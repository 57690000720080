import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_SIGNIN",
        forgotPassword: "b2c_1_RESET",
        editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://ludensoarplatformdev.b2clogin.com/ludensoarplatformdev.onmicrosoft.com/B2C_1_SIGNIN",
        },
        forgotPassword: {
            authority: "https://ludensoarplatformdev.b2clogin.com/ludensoarplatformdev.onmicrosoft.com/b2c_1_RESET",
        },
        editProfile: {
            authority: "https://ludensoarplatformdev.b2clogin.com/ludensoarplatformdev.onmicrosoft.com/b2c_1_edit_profile"
        }
    },
    authorityDomain: "ludensoarplatformdev.b2clogin.com"
}

export const msalConfig = {
    auth: {
        clientId: "f7cd0530-2661-4f2a-9712-512e164382a3",
        authority: b2cPolicies.authorities.signUpSignIn.authority, 
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const protectedResources = {
    api: {
        endpoint: "https://app-ludensoarplatform-dev-001.azurewebsites.net/api/",
        scopes: ["https://ludensoarplatformdev.onmicrosoft.com/a03668a0-1866-40f5-a4d6-62fef941bb2e/Publisher.Edit"]
    },
}

 export const loginRequest = {
    scopes: [...protectedResources.api.scopes]
};
